import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { withRouter } from 'react-router-dom';

import { getCreateOrderBlock } from 'store/common/content';

import { Link } from 'components';
import { Text } from 'components/Text';
import { Button } from 'components/Button';
import { CREATE_ORDER_ROUTE, INSTRUCTION_FULL_ROUTE } from 'constants/routes';
import BaseActionBlock from '../BaseActionBlock';

import styles from '../AppsBlock/styles.pcss';
const cx = classNames.bind(styles);

const CreateOrderBlock = ({ history, content, isCompanyExist }) => {
  const onButtonClick = () => history.push(CREATE_ORDER_ROUTE);

  return (
    <BaseActionBlock
      className={cx('createOrder')}
      buttonSection={(
        <Button
          className={cx('button', { disabled: !isCompanyExist })}
          onClick={onButtonClick}
          isDisabled={!isCompanyExist}
          isWide
        >
          {content.buttonText}
        </Button>
      )}
    >
      <Text isTitle isLarge>
        {content.title}
      </Text>
      <Text isSmall isInterphases isSecondary className={cx('description')}>
        {content.description}
      </Text>

      <Text className={cx('link')} isSecondary>
        <Link href={INSTRUCTION_FULL_ROUTE} target="_blank">
          {content.instructionLink}
        </Link>
      </Text>
    </BaseActionBlock>
  );
};

const mapStateToProps = (state) => ({
  content: getCreateOrderBlock(state),
});

CreateOrderBlock.propTypes = {
  history: PropTypes.object,
  content: PropTypes.shape(({
    title: PropTypes.string,
    description: PropTypes.string,
    buttonText: PropTypes.string,
    instructionLink: PropTypes.string,
  })),
  isCompanyExist: PropTypes.bool,
};

export default compose(connect(mapStateToProps), withRouter)(CreateOrderBlock);
