import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { getLoadingText } from 'store/common/content';

import { Text } from 'components/Text';
import CreateOrderBlock from '../CreateOrderBlock';
import EditOrderBlock from '../EditOrderBlock';
import styles from './styles.pcss';
const cx = classNames.bind(styles);

const AppsBlock = ({ isOrdersLoaded, isCompanyExist, orders, loadingText }) => {
  return (
    <>
      <Text className={cx('headText')}>Приложения</Text>
      <div className={cx('orders')}>
        {/*<div className={cx('balance')}>*/}
        {/*  <BalanceBlock />*/}
        {/*</div>*/}
        {
          isOrdersLoaded || !isCompanyExist ?
            <>
              <div key="create-order" className={cx('orderBlock')}>
                <CreateOrderBlock isCompanyExist={isCompanyExist} />
              </div>
              {
                orders?.map(order => (
                  <div key={order.id} className={cx('orderBlock')}>
                    <EditOrderBlock
                      id={order.id}
                      status={order.currentStatusAlias}
                      name={order.applicationName}
                      date={order.createdAt}
                    />
                  </div>
                ))
              }
            </>
            : loadingText
        }
      </div>
    </>
  );
};

AppsBlock.propTypes = {
  isOrdersLoaded: PropTypes.bool,
  isCompanyExist: PropTypes.bool,
  loadingText: PropTypes.string,
  orders: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    companyName: PropTypes.string,
    currentStatusAlias: PropTypes.string,
    applicationName: PropTypes.string,
    createdAt: PropTypes.string,
    user: PropTypes.string,
    orderHistory: PropTypes.array,
  })),
};

const mapStateToProps = (state) => ({
  loadingText: getLoadingText(state),
});

export default connect(mapStateToProps)(AppsBlock);
